<template>
  <div>
    <van-cell title="日期" :value="date.val" @click="date.show = true" is-link />
    <van-calendar v-model="date.show" type="range" @confirm="dateSel" :allow-same-day="true" :min-date="date.minDate" />

    <van-cell title="类型" :value="type.val" @click="type.show = true" is-link v-if="show" />
    <van-popup v-model="type.show" round position="bottom">
      <van-picker :columns="type.lis" show-toolbar @cancel="type.show = false" @confirm="typeSel" value-key="NAME" />
    </van-popup>

    <van-cell title="状态" :value="state.val" @click="state.show = true" is-link v-if="show" />
    <van-popup v-model="state.show" round position="bottom">
      <van-picker :columns="state.lis" show-toolbar @cancel="state.show = false" @confirm="stateSel" value-key="NAME" />
    </van-popup>

    <van-empty description="暂无数据" v-if="grid.ls.length == 0" />
    <div v-else>
      <van-notice-bar left-icon="volume-o" text="直接点击记录，可以看详情和审批处理。" />
      <van-list :finished="grid.finish" @load="loadPage" v-model="grid.loading" :immediate-check="false"
        style="padding: 10px 0px;">
        <div class="wcard" v-for="item in grid.ls" :key="item.ID" @click="edit(item.ID)">
          <div class="bd">
            <van-row :gutter="8">
              <van-col :span="24">
                <p>标题：{{ item.TITLE }}</p>
                <p style="font-size: 14px; color: #999">单据类型：{{ item.CAT_TXT }}</p>
                <p style="font-size: 14px; color: #999">单号：{{ item.SN }}</p>
                <p style="font-size: 14px; color: #999">分类：{{ item.CAT_TXT }}</p>
                <p style="font-size: 14px; color: #999">金额：￥{{ item.RAMT }} 元</p>
                <p style="font-size: 14px; color: #999" v-if="item.STA == 10">步骤：{{ item.STEP_DESC }}</p>
              </van-col>
            </van-row>
          </div>
          <div class="ft">
            <van-row>
              <van-col :span="8" :class="item.STA == 10 ? '' : 'ov'">
                状态：{{ item.STA_TXT }}
              </van-col>
              <van-col :span="8">提交人：{{ item.SUB_NAME }}</van-col>
              <van-col :span="8">日期：{{ item.CREATED_DT | datetime('YYYY-MM-DD') }}</van-col>
            </van-row>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "lst",
  data() {
    return {
      grid: {
        sea: {
          PAGE_SIZE: 10,
          PAGE_INDEX: -1,
          CAT: 0,
          DTS: sessionStorage.getItem('dateS') || '',
          DTE: sessionStorage.getItem('dateE') || ''
        },
        ls: [],
        total: 0,
        loading: false,
        finish: false
      },
      date: {
        val: '',
        show: false,
        minDate: moment('2021-07-01').toDate()
      },
      type: {
        show: false,
        val: '全部',
        lis: [{ NAME: '全部', KEY: 0 }, { NAME: '采购', KEY: 100 }, { NAME: '领用', KEY: 200 }]
      },
      state: {
        show: false,
        val: '全部',
        lis: [{ NAME: '全部', KEY: 0 }, { NAME: '处理中', KEY: 10 }, { NAME: '撤消', KEY: 20 }, { NAME: '拒绝', KEY: 30 }, { NAME: '完结', KEY: 90 }]
      },
      show: true
      
    }
  },
  created() {  
  },
  mounted() {
  },
  activated(){
    this.grid.sea.FOR = this.$route.query.for;
    this.grid.sea.STA = this.$route.query.STA;
    if (this.$route.query.STA == 10) {
      document.title = '待审记录'
      this.show = false     
    }
    if (sessionStorage.getItem('dateS') && sessionStorage.getItem('dateE')) {
      this.date.val = sessionStorage.getItem('dateS') + ' 至 ' + sessionStorage.getItem('dateE')
    }
    this.filter();
  },
  methods: {
    filter() {
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.grid.finish = false;
      this.getList();
    },
    loadPage() {
      this.grid.sea.PAGE_INDEX += 1;
      this.getList();
    },
    dateSel(d) {
      this.grid.sea.DTS = moment(d[0]).format('YYYY-MM-DD');
      this.grid.sea.DTE = moment(d[1]).format('YYYY-MM-DD')
      this.date.show = false;
      this.date.val = this.grid.sea.DTS + ' 至 ' + this.grid.sea.DTE;
      this.filter();
    },
    typeSel(e) {
      console.log(e)
      this.type.val = e.NAME
      this.grid.sea.CAT = e.KEY
      this.type.show = false
      this.filter();
    },
    stateSel(e) {
      this.state.val = e.NAME
      this.grid.sea.STA = e.KEY
      this.state.show = false
      this.filter();
    },
    getList() {
      let self = this;
      self.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/Mobile/MAT/MVActApi/GetList",
        data: self.grid.sea,
        finally() { self.grid.loading = false; },
        completed: function (its, n) {
          self.grid.ls.push(...its.ITEMS);
          if (self.grid.ls.length >= n) self.grid.finish = true;
        }
      })
    },
    edit(id) {
      sessionStorage.setItem('dateS', this.grid.sea.DTS)
      sessionStorage.setItem('dateE', this.grid.sea.DTE)
      sessionStorage.setItem('pIndex', this.grid.sea.PAGE_INDEX)
      this.$router.push({ path: "/mat/valid/edit", query: { ID: id } });
    }
  }
}
</script>

<style scoped>
.wcard {
  margin: 0 12px 12px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, .1)
}

.wcard .ft {
  border-top: 1px dashed #ebedf0;
  padding: 8px 16px;
  font-size: 12px;
  min-height: 20px
}

.wcard .ft .ov {
  color: #ee0a24;
  font-weight: bold
}

.wcard .bd {
  padding: 0px 15px
}

.wcard .bd .lf {
  text-align: center;
  color: #ee0a24;
  padding-top: 10px
}

.wcard .bd .lf h2 {
  font-size: 30px;
  font-weight: 500;
  display: inline;
}

.wcard .bd .lf span {
  font-size: 40%;
  font-weight: normal;
  margin-left: 2px
}

.wcard .bd .lf p {
  font-size: 14px;
  line-height: 16px
}
</style>
